<template>
  <div class="report-page">
    <div :id="'x-' + y" v-for="y in 5" :key="y">
      <div v-for="x in 5" :id="`id_${x}_${y}`" :key="y" :data-index="y + x" class="card-images" :class="{
                      'right-answer' : testAns[testPage.page].right.includes(`${x}_${y}`) ,
                      'right-selected' : testPage.answer.includes(`${x}_${y}`) && testPage.right[testPage.answer.indexOf(`${x}_${y}`)],               
                      'wrong-answer': (testPage.answer.includes(`${x}_${y}`) && !testPage.right[testPage.answer.indexOf(`${x}_${y}`)]),
                      'page-tooltip': testPage.answer.includes(`${x}_${y}`) || testAns[testPage.page].right.includes(`${x}_${y}`)           
                    }">
        <img :src="testImages[parseInt(x) - 1][parseInt(y) - 1]" width="100%" height="100%" alt="Diko Test" />
        <span class="page-tooltip tooltip-text" v-if="testPage.answer.includes(`${x}_${y}`)">
          {{testPage.page >= 16 ? `${testPage.answer.indexOf(`${x}_${y}`) + 1}-${testPage.right[testPage.answer.indexOf(`${x}_${y}`)] ? $t('Correct') : $t('Wrong')}` : ''}}
          {{ (parseInt(testPage.time[testPage.answer.indexOf(`${x}_${y}`)])/1000).toFixed(3) }}sn </span>
        <span class="page-tooltip tooltip-text" v-else-if="testAns[testPage.page].right.includes(`${x}_${y}`)">
          {{$t('Right Answer')}}
        </span>
      </div>
    </div>
    <div class="text-desc-area">
      <b-badge :variant="testPage.right[index] ? 'success' : testPage.time[index] >= 30000 ? 'secondary' : 'danger'"
        v-for="(item, index) in testPage.time" :key="'badges'+index" :id="testPage.answer[index]"
        @mouseover="grayout(testPage.answer[index],testPage.right[index])" @mouseout="resetGrayout">
        {{ index + 1 }} - {{ (parseInt(item) / 1000).toFixed(1) }} sn
        <!-- {{ item ? "Doğru" : testPage.time[index] >= 30000 ? "Boş" : "Yanlış" }} -->
        <br />
      </b-badge>
    </div>
  </div>
</template>
<script>
//  import svgComponent from "./svg.vue"; 
import {BBadge} from 'bootstrap-vue'

export default {
  components:{
    BBadge
  },
  props:{
    testPage:{
        type: Object,
      }
  },
  data() {
    return {  
    testImages: [[], [], [], [], []],
    testAns :require("@/../../server/config/testParams.json"),

    }
  },
  created() {
    console.log("test page created");
    let page=[];
    let temp = [];
    for (let x = 1; x <= 5; x++) {
      for (let y = 1; y <= 5; y++) {
        temp.push(
          require(`@/assets/test-images/${this.testPage.page<16?this.testPage.page:this.testPage.page+this.testPage.page-15}/${x}-${y}.svg`)
        );
      }
      page.push(temp);
      temp = [];
    }
    this.testImages=page;
    console.log(this.testImages);
  },
  methods: {
    grayout(elem, right) {
      if(elem == "0_0") return;
      let elems = document.querySelectorAll(`.card-images:not(#id_${elem})${!right ? ":not(.right-answer:not(.right-selected))" : ""}`);
      elems.forEach((item)=>{
        item.classList.add('grayout');
      })
    },
    resetGrayout(){
      console.log("reset");
      let elems = document.querySelectorAll(`.grayout`);
      elems.forEach((item)=>{
        item.classList.remove('grayout');
      })
    }
  },
};
</script>
<style scoped lang="scss">
@import "../stylesheets/testArea.css";
@import "@core/scss/vue/libs/vue-good-table.scss";

.card-images {
  opacity: 1 !important;
  background-color: #fff;
  width: min(10vw,10vh);
  height: min(10vw,10vh);
  
}
.text-desc-area {
  margin-left: 10px;
  // width: 100px !important;
  // font-weight: 600;
  z-index: 1;
  // background: black;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.grayout {
  opacity: 0.2 !important;
}





.report-page {
  width: 100vw;
  display: flex;
}

.right-answer:not(.right-selected)>img {
  // border: 5px solid #00b894 !important;
  border-radius: 15%;
  box-shadow: 0 0 5px 5px #00cfe8;
}

.right-selected>img {
  border-radius: 15%;
  box-shadow: 0 0 5px 5px rgb(40, 199, 111);
  // border: 5px solid rgb(40, 199, 111);
}

.wrong-answer>img {
  // border: 5px solid #d63031 !important;
  border-radius: 15%;
  box-shadow: 0 0 5px 5px #d63031 !important;
}

.page-tooltip {
  position: relative;
  // display: inline-block;
}

.page-tooltip .tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: $primary;
  white-space: break-spaces;
  color: #fff;
  background: white;
  text-align: center;
  padding: 10px;
  border-radius: 6px;

  // /* ff 3.6+ */
  // background: -moz-radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(238, 130, 238, 0) 100%, rgba(255, 255, 255, 0.5) 100%);

  // /* safari 5.1+,chrome 10+ */
  // background: -webkit-radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(238, 130, 238, 0) 100%, rgba(255, 255, 255, 0.5) 100%);

  // /* opera 11.10+ */
  // background: -o-radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(238, 130, 238, 0) 100%, rgba(255, 255, 255, 0.5) 100%);

  // /* ie 10+ */
  // background: -ms-radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(238, 130, 238, 0) 100%, rgba(255, 255, 255, 0.5) 100%);

  // /* global 92%+ browsers support */
  // background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(238, 130, 238, 0) 100%, rgba(255, 255, 255, 0.5) 100%);

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.wrong-answer>.tooltip-text {
  // box-shadow: 0 0 10px 10px #4f4f4f;
  box-shadow: 0 0 10px 5px #d63031;
  text-shadow: 0 0 2px #d63031;
  color: #d63031;
  font-weight: bolder;
  border: 1px solud #d63031;
}

.right-selected>.tooltip-text {
  box-shadow: 0 0 10px 5px rgb(40, 199, 111);
  text-shadow: 0 0 2px rgb(40, 199, 111);
  color: rgb(40, 199, 111);
  font-weight: bolder;
  border: 1px solid rgb(40, 199, 111)
}

.right-answer:not(.right-selected)>.tooltip-text {
  box-shadow: 0 0 10px 5px #00cfe8;
  text-shadow: 0 0 2px #00cfe8;
  color: #00cfe8;
  font-weight: bolder;
  border: 1px solid #00cfe8
}

.page-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.card-images:not(.right-answer):not(.wrong-answer):not(.grayout) {
  opacity: 0.6 !important;
}
@media screen and (max-width: 768px) {
  .text-desc-area {
    margin-left: 5px;
    font-size: 10px;
    width: 15%;
  }
}

</style>















































































































































































































































































































































































































































































































































































































































































































































































































































































<style>